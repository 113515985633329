import { useState, useEffect } from "react";
import KRGlue from "@lyracom/embedded-form-glue";
import axios from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import NotFound from "../../pages/NotFound/NotFound";
import { setErrorMsg } from "../../store/slices/bookingParamSlice";
import { useTranslation } from "react-i18next";

function PaymentForm() {
  const [token, setToken] = useState("");
  const { orderId, email, reservationType, errorMsg, mode } = useSelector(
    (state) => state.bookingParam
  );
  const selectedLang = useSelector((state) => state.language.selectedLang);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const setupPaymentForm = async () => {
      const endpoint = "https://api.payzen.eu";
      const publicKey = "%REACT_APP_PAYZEN_PUBLIC_KEY%";

      let formToken = token;

      try {
        const res = await axios.post("/payment/create-token", {
          currency: "EUR",
          orderId: orderId,
          mode: mode,
          customer: {
            email: email,
          },
          reservationType: reservationType,
        });
        formToken = res.data.formToken;

        const { KR } = await KRGlue.loadLibrary(endpoint, publicKey);
        await KR.setFormConfig({
          formToken: formToken,
          "kr-language": `${selectedLang === "fr"
            ? "fr-FR"
            : selectedLang === "en"
              ? "en-EN"
              : "de-DE"
            }`
        });

        const { result } = await KR.renderElements("#myPaymentForm");
        await KR.showForm(result.formId);

        await KR.onSubmit(async (paymentData) => {
          const transactionInfo = {
            orderId: paymentData.clientAnswer.orderDetails.orderId,
            orderCycle: paymentData.clientAnswer.orderCycle,
            orderStatus: paymentData.clientAnswer.orderStatus,
            orderDetails: {
              orderTotalAmount:
                paymentData.clientAnswer.orderDetails.orderTotalAmount,
              orderEffectiveAmount:
                paymentData.clientAnswer.orderDetails.orderEffectiveAmount,
              orderCurrency:
                paymentData.clientAnswer.orderDetails.orderCurrency,
              mode: paymentData.clientAnswer.orderDetails.mode,
              orderId: paymentData.clientAnswer.orderDetails.orderId,
            },
            customer: {
              reference: paymentData.clientAnswer.customer.reference,
              email: paymentData.clientAnswer.customer.email,
            },
            billingDetails: {
              firstName:
                paymentData.clientAnswer.customer.billingDetails.firstName,
              lastName:
                paymentData.clientAnswer.customer.billingDetails.lastName,
              phoneNumber:
                paymentData.clientAnswer.customer.billingDetails.phoneNumber,
              streetNumber:
                paymentData.clientAnswer.customer.billingDetails.streetNumber,
              address: paymentData.clientAnswer.customer.billingDetails.address,
              city: paymentData.clientAnswer.customer.billingDetails.city,
              state: paymentData.clientAnswer.customer.billingDetails.state,
              country: paymentData.clientAnswer.customer.billingDetails.country,
              zipCode: paymentData.clientAnswer.customer.billingDetails.zipCode,
            },
            transaction: {
              uuid: paymentData.clientAnswer.transactions[0].uuid,
              currency: paymentData.clientAnswer.transactions[0].currency,
              paymentMethodType:
                paymentData.clientAnswer.transactions[0].paymentMethodType,
              paymentMethodToken:
                paymentData.clientAnswer.transactions[0].paymentMethodToken,
              status: paymentData.clientAnswer.transactions[0].status,
              card: {
                pan: paymentData.clientAnswer.transactions[0].transactionDetails
                  .cardDetails.pan,
                expiryMonth:
                  paymentData.clientAnswer.transactions[0].transactionDetails
                    .cardDetails.expiryMonth,
                expiryYear:
                  paymentData.clientAnswer.transactions[0].transactionDetails
                    .cardDetails.expiryYear,
                country:
                  paymentData.clientAnswer.transactions[0].transactionDetails
                    .cardDetails.country,
              },
            },
          };

          const response = await axios.post(
            "/payment/transaction-success",
            transactionInfo
          );

          if (response.status === 200) {
            sessionStorage.setItem("success", true);
            const pathWithLang = `/${selectedLang}/success`;
            navigate(pathWithLang);
          } else if (response.status === 500) {
            dispatch(setErrorMsg(response.message));
          }
          return false;
        });

        await KR.onError(async (event) => {
          const response = await axios.post(
            `/payment/transaction-failed`,
            { reference: orderId }
          )
          console.log(response)
        })

      } catch (error) {
        error && dispatch(setErrorMsg(error.message));
      }
    };

    setupPaymentForm();
  }, [orderId, email, reservationType, errorMsg, mode, selectedLang]);

  return (
    <>
      {errorMsg ? (
        <NotFound />
      ) : (
        <div className="form mb-10 flex flex-col items-center justify-center">
          <div className="container">
            <div
              id="myPaymentForm"
              className="flex items-center justify-center w-full"
            >
              <div className="kr-embedded">
                <div className="kr-pan"></div>
                <div className="kr-expiry"></div>
                <div className="kr-security-code"></div>
                <div className="kr-form-error"></div>
                <button className="kr-payment-button"></button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentForm;