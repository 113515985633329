import React from "react";
import PaymentForm from "../../components/PaymentForm/PaymentForm";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Payment() {
  const { reservationType, orderId, totalAmountToBlock } = useSelector(
    (state) => state.bookingParam
  );
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!orderId.length) {
    location.pathname.includes("hotel")
      ? navigate(`/hotel`)
      : navigate(`/restaurant`);
  }

  return (
    <>
      {reservationType.length && (
        <div className="mb-36 flex flex-col gap-5 md:justify-around col-span-2 bg-white border rounded p-7">
          <p className="mx-7 2xl:mx-20 font-semibold mt-2 text-red-600 text-center">
            {location.pathname.includes("restaurant/payment") ||
              reservationType === "R"
              ? `${t("payment_resto_msg", {
                totalAmountToBlock: totalAmountToBlock,
              })}`
              : location.pathname.includes("hotel/payment") ||
                reservationType === "H"
                ? `${t("payment_hotel_msg", {
                  totalAmountToBlock: totalAmountToBlock,
                })}`
                : `${t("payment_hotel_msg", {
                  totalAmountToBlock: totalAmountToBlock,
                })}`}
          </p>
          <p></p>
          <PaymentForm />
          {location.pathname.includes("restaurant/payment") ||
            reservationType === "R" ? (
            <div>
              <p id="para" className="2xl:text-sm text-xs mt-2">
                {t("rest_garantee_conditions")}
              </p>
              <p className="text-sm mt-2">
                {t("accept_conditions_check")}<br />
                <a
                  href="https://villarenelalique.com/fr/conditions-generales-de-vente.html"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-primary ml-1"
                >
                  {t("link_cgv")}
                </a>
              </p>
            </div>
          ) : (
            <div>
              <p id="para" className="2xl:text-sm text-xs mt-2">
                {t("hotel_garantee_conditions")}
              </p>
              <p className="text-sm mt-2">
                {t("accept_conditions_check")}<br />
                <a
                  href="https://villarenelalique.com/fr/conditions-generales-de-vente.html"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-primary ml-1"
                >
                  {t("link_cgv")}
                </a>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Payment;